import Vue from "vue";
import Vuex from "vuex";
import { Identity, Lang, Settings } from "@gc/models";

Vue.use(Vuex);

interface StateInterface {
  identity: Identity;
  settings: Settings;
  breadcrumbItems: any[];
}

export default new Vuex.Store<StateInterface>({
  state: {
    identity: Identity.load(),
    settings: Settings.load(),
    breadcrumbItems: [],
  },
  mutations: {
    setLang(state, lang: Lang) {
      state.settings.updateLang(lang);
    },
    setUserName(state, context: { firstName: string; lastName: string }) {
      state.identity.updateName(context);
    },
    setIdentity(state, identity: Identity) {
      state.identity = identity;
      state.identity.save();
    },
    setBreadcrumb(state, items: any[]) {
      state.breadcrumbItems = items;
    },
  },
});
