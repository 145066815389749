import NotFound from "@/views/404.vue";
import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import store from "@/store";
import { NavigationGuardNext } from "vue-router/types/router";
import { URLs } from "@gc/urls";
import { systemAdminRoutes } from "./routes";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "",
    component: () => import("@/views/index.vue"),
    children: systemAdminRoutes,
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
      const isAdmin = store.state.identity.isSystemAdmin;
      if (isAdmin) {
        next();
      } else {
        const redirectUrl = to.params.redirect ?? new URL(to.fullPath, window.location.origin).href;
        next(URLs.admin.identity(redirectUrl));
      }
    },
  },
  {
    path: URLs.admin.identity(),
    component: () => import("@/views/identity.vue"),
  },
  {
    path: URLs.admin.logout(),
    component: () => import("@/views/logout.vue"),
  },
  {
    path: "*",
    component: () => import("@/views/index.vue"),
    children: [
      {
        path: "*",
        component: NotFound,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
