import store from "@/store";
import { GenericApi, Api } from "@gc/helpers";
import { StringHelper } from "@gc/helpers/src/string-helper";
import { URLs } from "@gc/urls";
import router from "@/router";

declare module "vue/types/vue" {
  interface Vue {
    $api: GenericApi;
  }
}

export const api = new Api(
  process.env.VUE_APP_ENDPOINT,
  StringHelper.instance.parseBoolean(process.env.VUE_APP_LOG_AXIOS_FAILURES, false),
  () => router.push(URLs.admin.logout(window.location.href)),
  () => {
    const headers: Record<string, string> = {};
    headers['X-LANGUAGE'] = store.state.settings.lang;
    return headers;
  }
);

export default {
  install(Vue: any) {
    Vue.prototype.$api = new GenericApi(api);
  },
};
